import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { APP_BASE_HREF } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { environment } from "../environments/environment";
import { AuthModule } from "@auth0/auth0-angular";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";

// Services
import { AuthService } from "./services/auth.service";

// Components
import { AppComponent } from "./app.component";
import { NbThemeModule } from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MyHttpInterceptor } from "./core/http-interceptor";
import { CustomerDetailService } from "./services/customer-detail.service";
import { CommonService } from "./services/common.service";
import { SignalRService } from "./services/signalRService";
import { SnackBarNotificationService } from "./services/snackBar-notification.service";
import { I18nService } from "./core/i18n.service";
import { GoogleTranslateService } from "./services/translation/google-translate.service";
import { QuillModule } from "ngx-quill";
import { forkJoin, Observable } from "rxjs";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { map } from "rxjs/operators";
import { ArtificialInteligenceService } from "./services/artificial-inteligence.service";

export class CustomLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const defaultLanguage = "ro-RO";
    const supportedLanguages = ["en-US", "fr-FR", "ro-RO"];

    const selectedLang = supportedLanguages.includes(lang) ? lang : defaultLanguage;

    const translationRequests = supportedLanguages.map((language) => this.http.get(`/assets/i18n/${language}.json`));

    return forkJoin(translationRequests).pipe(
      map((translations) => {
        const result = {};

        translations.forEach((translation, index) => {
          const language = supportedLanguages[index];
          result[language] = translation;
        });

        return result[selectedLang];
      })
    );
  }
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    //HomeModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: CustomLoader, deps: [HttpClient] },
    }),
    NgbModule,
    QuillModule.forRoot(),
    NbEvaIconsModule,
    NbThemeModule,
    QuillModule.forRoot(),
    AuthModule.forRoot({
      // The domain and clientId were configured in the previous chapter
      domain: environment.identityUrl,
      clientId: environment.clientId,

      // Request this audience at user authentication time
      audience:  environment.audience, // environment.apiUrl,

      // Request this scope at user authentication time
      scope: "read:current_user read:current_user_metadata update:current_user_metadata",
    }),
    MatSnackBarModule
  ],
  declarations: [AppComponent],
  providers: [
    { provide: "ORIGIN_URL", useFactory: getBaseUrl },
    { provide: "IDENTITY_URL", useFactory: identityUrlFactory },
    { provide: "API_URL", useFactory: apiUrlFactory },
    { provide: "CLIENT_ID", useFactory: clientIdFactory },
    { provide: "OLX_REDIRECT_URI", useFactory: olxRedirectUriFactory },
    { provide: APP_BASE_HREF, useValue: "/" },
    AuthService,
    CustomerDetailService,
    CommonService,
    SignalRService,
    SnackBarNotificationService,
    GoogleTranslateService,
    I18nService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    ArtificialInteligenceService,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

export function identityUrlFactory() {
  return environment.identityUrl;
}

export function apiUrlFactory() {
  return environment.apiUrl;
}

export function clientIdFactory() {
  return environment.clientId;
}

export function olxRedirectUriFactory() {
  return environment.olxRedirectUri;
}
