import * as CryptoJS from 'crypto-js';

export class ProtectData {
  static protect(encryptionKey: string, value: string) {
    const encryptionKeyUtf = CryptoJS.enc.Utf8.parse(encryptionKey);
    const salt = CryptoJS.enc.Base64.parse('Q29lc3RpbTIwMTY=');
    try {
      const iterations = 1000;
      const keyAndIv = CryptoJS.PBKDF2(encryptionKeyUtf, salt,
        {
          keySize: 256 / 32 + 128 / 32,
          iterations: iterations,
          hasher: CryptoJS.algo.SHA1
        });

      const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);

      const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
      const iv = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(64, hexKeyAndIv.length));

      const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf16LE.parse(value.toString()), key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });

      return encrypted.toString();
    } catch (e) {
      return value;
    }
    
  }

  static unProtect(encryptionKey: string, value: string) {
    const encryptionKeyUtf = CryptoJS.enc.Utf8.parse(encryptionKey);
    const salt = CryptoJS.enc.Base64.parse('Q29lc3RpbTIwMTY=');
    try {
      const iterations = 1000;
      const keyAndIv = CryptoJS.PBKDF2(encryptionKeyUtf, salt,
        {
          keySize: 256 / 32 + 128 / 32,
          iterations: iterations,
          hasher: CryptoJS.algo.SHA1
        });

      const hexKeyAndIv = CryptoJS.enc.Hex.stringify(keyAndIv);

      const key = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(0, 64));
      const iv = CryptoJS.enc.Hex.parse(hexKeyAndIv.substring(64, hexKeyAndIv.length));

      const decrypted = CryptoJS.AES.decrypt(value, key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });
      const rawValue = decrypted.toString(CryptoJS.enc.Utf16LE);
      if (!rawValue) {
        return null;
      }
      return rawValue;
    } catch (e) {
      return null;
    }
    
  }
}
