import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { SignalRService } from "../../../services/signalRService";
import { OrderNotificationSignalR } from "../../../company/models/OrderNotificationSignalR";
import { CommonService } from "../../../services/common.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-order-notification",
  templateUrl: "./order-notification.component.html",
  styleUrls: ["./order-notification.component.scss"],
})
export class OrderNotificationComponent implements OnInit, OnDestroy {
  orderNotifications: Array<OrderNotificationSignalR>;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private signalRService: SignalRService,
    private commonService: CommonService,
    private cdref: ChangeDetectorRef
  ) {
    this._unsubscribeAll = new Subject();
    this.orderNotifications = new Array<OrderNotificationSignalR>();
    this.subscribeToOrderNotificationEvents();
    this.subscribeToStartOrStopSignalRConnection();
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  deleteNotifications() {
    this.orderNotifications = new Array<OrderNotificationSignalR>();
    this.cdref.detectChanges();
  }

  deleteNotification(index: number) {
    this.orderNotifications.splice(index, 1);
  }

  private subscribeToOrderNotificationEvents(): void {
    this.signalRService.orderNotification
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((message: OrderNotificationSignalR) => {
        if (this.orderNotifications.length >= 50) {
          this.orderNotifications.splice(0, 1);
        }
        this.orderNotifications.push(message);
      });
  }

  private subscribeToStartOrStopSignalRConnection() {
    this.commonService.startOrStopSignalRConnectionEventEmitter.subscribe((res: boolean) => {
      if (res == false) {
        this.deleteNotifications();
      }
    });
  }
}
