<span class="created-by">
  <a class="footer-links" href="#">Copyright © 2021 - {{ copyRightYear }}</a>
  <a href="./legal/termeni-si-conditii" class="footer-links" target="_blank"> Termeni și condiții </a>
  <a href="./legal/politica-de-confidentialitate" class="footer-links" target="_blank">
    Politica de confidențialitate
  </a>
</span>

<div class="socials">
  <a href="https://www.youtube.com/@syncap" target="_blank">
    <i class="fa fa-youtube" matTooltip="Urmărește-ne pe YouTube!"></i>
  </a>

  <a href="https://www.linkedin.com/company/ecapromania/" target="_blank">
    <i class="fa fa-linkedin" matTooltip="Dorim să fim contactul tău pe LinkedIn!"></i>
  </a>
  <a href="https://www.facebook.com/syncap.eu" target="_blank">
    <i class="fa fa-facebook-square" matTooltip="Așteptăm un like pe Facebook!"></i>
  </a>
  <a href="https://www.instagram.com/syncap.ro/" target="_blank">
    <i class="fa fa fa-instagram" matTooltip="Urmărește-ne pe Instagram!"></i>
  </a>
</div>

<a href="https://calendly.com/ecap-meetings" target="_blank">
<button mat-flat-button extended >
  <mat-icon fontSet="fa" fontIcon="fa-calendar"></mat-icon>Rezervă o întâlnire
</button>
</a>