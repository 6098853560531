import { ManufacturerBase } from "./ManufacturerBase";

export class ProductBaseLocaleCommon {
  name: string;
  description: string;
  shortDescription: string;
  seName: string;
  metaKeyword: string;
  metaTitle: string;
  metaDescription: string;
}

export class ProductBase extends ProductBaseLocaleCommon {

  constructor() {
    super();
    this.categoryIds = new Array<ProductCategoryBase>();
    this.images = new Array<ProductImageBase>();
    this.locales = new Array<ProductBaseLocale>();
  }

  id: string;
  baseIdentifier: string;
  sku: string;
  ean: string;
  price: number;
  minSalePriceLimit: number;
  maxSalePriceLimit: number;
  recommendedPrice: number;
  stockQuantity: number;
  manufacturer: ManufacturerBase;
  vatRate: ProductVatBase;
  currency: string;
  status: boolean;
  categoryIds: Array<ProductCategoryBase>;
  attributeSetId: number;
  images: Array<ProductImageBase>;
  locales: Array<ProductBaseLocale>;
  productAttributes: Array<ProductAttributeBase>;
  variants: Array<ProductVariant>;
  weight: number;
  height?: number
  manufacturerCode: string;
  modelCode: string;
  url: string;
  minQuantity?: number
  layerQuantity?: number
  palletQuantity?: number
  um?: string
  packingType?:string
  family: Family;

  isEditable: boolean = true;
}

export class ProductVariant {
  name: string;
  baseIdentifier: string;
  sku: string;
  ean: string;
  stockQuantity: number;
  price: number;
  recommendedPrice: number;
  status: boolean;
  variantAttributes: Array<ProductAttributeBase>;
  manufacturerProductCode: string;
}

export class ProductCategoryBase {
  productId: string;
  name?: string;
  categoryId: string;
}

export class ProductImageBase {
  id: number;
  position: number;
  mimeType: string;
  src: string;
  encodedImage: string;
  name: string;
}

export class ProductBaseLocale extends ProductBaseLocaleCommon {
  languageId: number;
}

export class ProductVatBase {
  id: number;
  isDefault: boolean;
  rate: string;
  name: string;
}

export class ProductAttributeBase {
  id: string;
  name: string;
  translatedName: string;
  isMandatory: boolean;
  isFamily: boolean;

  categoryId: string;

  attributeSetId: number;
  attributeSetName: string;

  attributeType: number;

  baseAttributeId: number;
  baseAttributeOptions: Array<ProductAttributeValueBase>;
}

export class ProductAttributeValueBase {
  id: string;
  name: string;
  translatedName: string;

  // for
  isUpdated: boolean;
}

export class ProductStatusBase {
  id: string;
  name: string;
  defaultStatus: boolean;
}

export class Family {
  id: string;
  typeId: string;
  name: string;
}
export class ProductAIProperties {
  name: string;
  description: string;
  shortDescription: string;
  seName: string;
  metaKeyword: string;
  metaTitle: string;
  metaDescription: string;
  productId: string;
  sku: string;
  ean: string;
  language: string;
  constructor(name: string, description: string, shortDescription: string, seName: string, metaKeyword: string, metaTitle: string, metaDescription: string,productId :string,sku:string,ean:string,language:string) {
    this.name = name;
    this.description = description;
    this.shortDescription = shortDescription;
    this.seName = seName;
    this.metaKeyword = metaKeyword;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.productId=productId;
    this.sku=sku;
    this.ean=ean;
    this.language = language;
  }
}
