import { LogApplication } from "../../core/models/enums/logApplication";
import { LogLevel } from "./Enums";

export class Log {
  logLevelId: number;
  shortMessage: string;
  fullMessage: string;
  createdOnUtc: Date;
  companyId: number;
  companyName: string;

  logApplication: LogApplication;
  identifier: string;
  url: string;

  //custom
  selected: boolean;

  constructor(log?: Log) {
    this.logLevelId = log.logLevelId || 0;
    this.shortMessage = log.shortMessage || '';
    this.fullMessage = log.fullMessage || '';
    this.createdOnUtc = new Date(log.createdOnUtc) || null;
  }

  get LogLevel(): string {
    return LogLevel[this.logLevelId];
  }
  set LogLevel(value: string) {
    this.logLevelId = LogLevel[value];
  }
}
