<div class="header-container" [class.left]="position === 'normal'" [class.right]="position === 'inverse'">
  <div class="logo-containter">
    <nb-actions size="medium">
      <nb-action
        *ngIf="!isLoadingDefaultCompany"
        (click)="toggleSidebar()"
        icon="menu"
        class="navigation"
        style="padding-left: 0px"
      >
      </nb-action>
      <nb-action *ngIf="isLoadingDefaultCompany">
        <app-loader [isLoading]="true" [size]="10"></app-loader>
      </nb-action>
    </nb-actions>
    <div class="logo" (click)="goToHome()" style="cursor: pointer">
      <img [src]="imageSrc" />
    </div>
    <!-- <div class="logo" (click)="goToHome()">ngx-<span>admin</span></div> -->
  </div>
  <span style="flex: 1 1 0%"></span>
  <nb-actions size="medium" class="navigation settings-navigation">
    <nb-action
      (click)="toggleSettingsMenu()"
      icon="{{ settingsMenuHidden ? 'settings-2-outline' : 'close' }}"
      class="nav-action"
    >
    </nb-action>
  </nb-actions>
  <!-- <ngx-theme-switcher></ngx-theme-switcher> -->
</div>

<mat-divider *ngIf="!settingsMenuHidden" style="margin-top: 20px"></mat-divider>
<nb-actions
  size="medium"
  [ngClass]="{ hide: settingsMenuHidden }"
  class="header-container"
  [class.right]="position === 'normal'"
  [class.left]="position === 'inverse'"
>
  <nb-action
    icon="bell-outline"
    *ngIf="authService.isAuthenticated"
    class="notifications"
    (click)="toggleSettings()"
  ></nb-action>

  <nb-action *ngIf="authService.isAuthenticated">
    <nb-user
      [nbContextMenu]="userMenu"
      [name]="userData?.name"
      [picture]="userData?.picture"
      nbContextMenuTag="my-context-menu"
    ></nb-user>
    <!--<button [nbContextMenu]="userMenu">My Account</button>-->
  </nb-action>

  <nb-action *ngIf="!authService.isAuthenticated">
    <a class="nav-item nav-link text-uppercase" (click)="login()">Login</a>
  </nb-action>

  <!--<nb-action class="control-item" disabled icon="nb-notifications"></nb-action>
  <nb-action class="control-item" icon="nb-email"></nb-action>-->

  <nb-action
    *ngIf="authService.isAuthenticated && !isLoadingDefaultCompany"
    icon="settings-2-outline"
    (click)="gotoEdit()"
  ></nb-action>

  <nb-action *ngIf="isDemo">
    <button mat-flat-button extended color="warn" routerLink="/companies/add">
      <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>{{ "company-settings.company-add" | translate }}
    </button>
  </nb-action>
  <nb-action *ngIf="isLoadingDefaultCompany">
    <app-loader [isLoading]="true" [size]="10"></app-loader>
  </nb-action>

  <nb-action *ngIf="authService.isAuthenticated">
    <div *ngIf="!customerDetail">
      Nicio firma existenta! &nbsp;
      <i class="fa fa-info-circle" matTooltip="Adaugati o firma la contul dumneavoastra!"></i>
    </div>
    <ng-container *ngIf="companyMenu && companyMenu.length > 0">
      <nb-user
        [nbContextMenu]="companyMenu"
        [name]="currentSelectedCompany?.name"
        nbContextMenuTag="company-context-menu"
      ></nb-user
    ></ng-container>
    <!--<button [nbContextMenu]="userMenu">My Account</button>-->
  </nb-action>
</nb-actions>

<!-- <header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="/" translate>APP_NAME</a>
    <a class="navbar-brand" href="/">
      <img class="logo" src="assets/logo.png" alt="GlocalMentor Logo" />
    </a>
    <button class="navbar-toggler" type="button" aria-controls="navbar-menu"
            aria-label="Toggle navigation" (click)="toggleMenu()" [attr.aria-expanded]="!menuHidden">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav">
          <a class="nav-item nav-link text-uppercase"
             (click)="enableDarkTheme()" routerLinkActive="active">
            <i class="fa fa-home"></i>
            <span translate>Enable dark theme</span>
          </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <i class="fa fa-home"></i>
          <span translate>Home</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/about" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>About</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/requirements" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>Requirements</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/foundations" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>Foundations</span>
        </a>
        <a class="nav-item nav-link text-uppercase"
           *ngIf="authService.isAuthenticated" routerLink="/fetch-data" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>Foundations2</span>
        </a>
        <a class="nav-item nav-link text-uppercase"
           *ngIf="!authService.isAuthenticated" (click)="login()" routerLink="/login" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>Login</span>
        </a>
        <a class="nav-item nav-link text-uppercase"
           *ngIf="authService.isAuthenticated" (click)="logout2()" routerLinkActive="active">
          <i class="fa fa-question-circle"></i>
          <span translate>Logout</span>
        </a>
      </div>
      <div class="navbar-nav ml-auto">
        <div class="nav-item" ngbDropdown placement="bottom-right">
          <a id="language-dropdown" class="nav-link" ngbDropdownToggle>{{currentLanguage}}</a>
          <div ngbDropdownMenu aria-labelledby="language-dropdown">
            <button class="dropdown-item" *ngFor="let language of languages" (click)="setLanguage(language)">
              {{language}}
            </button>
          </div>
        </div>
        <div class="nav-item" ngbDropdown placement="bottom-right">
          <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
            <i class="fa fa-user-circle"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown">
            <h6 class="dropdown-header">
              <span translate>Logged in as</span> <b>{{username}}</b>
            </h6>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="logout()" translate>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header> -->
