import { Log } from "./Log";

export class SyncProductsNotificationSignalR {
  logs: Array<Log>;
  totalProducts: number;
  synchronizedProducts: number;
  pageIndex: number;
  productCode: string;
  productId: string;
  companyProductId: string;

  //custom
  containWarningLog: boolean;
  containErrorLog: boolean;

  constructor(syncProductsNotificationSignalR?: SyncProductsNotificationSignalR) {
    if (syncProductsNotificationSignalR) {
      this.logs = syncProductsNotificationSignalR.logs;
      this.totalProducts = syncProductsNotificationSignalR.totalProducts;
      this.synchronizedProducts = syncProductsNotificationSignalR.synchronizedProducts;
      this.pageIndex = syncProductsNotificationSignalR.pageIndex;
      this.productCode = syncProductsNotificationSignalR.productCode;
      this.productId = syncProductsNotificationSignalR.productId;
      this.companyProductId = syncProductsNotificationSignalR.companyProductId;
    }
  }
}
