import { Component, OnInit, OnDestroy, Input, ViewChild } from "@angular/core";
import { I18nService } from "../../i18n.service";
import { Subject } from "rxjs";
import { NbThemeService, NbSidebarService, NbMenuService, NbContextMenuDirective } from "@nebular/theme";
import { concatMap, filter, switchMap, takeUntil, tap } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CustomerDetailService } from "../../../services/customer-detail.service";
import { AuthService } from "../../../services/auth.service";
import { UserDetail } from "../../../company/models/UserDetail";
import { Router } from "@angular/router";
import { Company } from "../../../company/models/Company";
import { CompanySalechannelModuleService } from "../../../company/company-salechannel-module/company-salechannel-module.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  menuHidden = true;
  private _unsubscribeAll: Subject<any>;
  userData: any;

  @Input() position = "normal";
  isAuthorized = false;

  user: any;
  isDemo: boolean = false;

  userMenu = [{ title: "Companiile mele" }, { title: "Facturi" }, { title: "Log out" }];
  companyMenu = [];
  customerDetail: UserDetail;
  currentSelectedCompanyId = 0;

  isLoadingDefaultCompany = false;
  @ViewChild(NbContextMenuDirective) contextMenu: NbContextMenuDirective;
  settingsMenuHidden = true;

  imageSrc = "assets/images/e-cap_official_logo.png";
  currentSelectedCompany: Company;

  constructor(
    public authService: AuthService,
    private i18nService: I18nService,
    public customerDetailService: CustomerDetailService,
    private themeService: NbThemeService,
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    if (!location.host.includes("e-cap.ro") && !location.host.includes("localhost")) {
      this.imageSrc = "assets/images/it-supermarket_logo.png";
    }
    if (location.host.includes("raisis") ) {
      this.imageSrc = "assets/images/raisis-lockup.png";
    }
    if (location.host.includes("qa") ) {
      this.imageSrc = "assets/images/syncap-lockup.png";
    }
    this.authService
      .getUserData()
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((userData: any) => {
          this.userData = userData;
          if (this.authService.isSuperAdmin && !this.userMenu.some((x) => x.title === "Management facturi")) {
            this.userMenu.splice(this.userMenu.length - 1, 0, { title: "Management facturi" });
          }
        }),
        switchMap((res) => {
          return this.customerDetailService.loadedCustomerObservable.pipe(
            tap((res: UserDetail) => {
              if (res == null) return;
              this.customerDetail = res;
              if (
                this.customerDetail.allCompanies.length == 1 &&
                this.customerDetail.allCompanies.some((x) => x.id == 109)
              ) {
                this.isDemo = true;
              }
              this.companyMenu = this.customerDetail.allCompanies.map((c) => {
                return {
                  title: c.name,
                  data: c.id,
                };
              });
              this.currentSelectedCompanyId = this.customerDetail.currentCompany.id;
              this.currentSelectedCompany = this.customerDetail.currentCompany;
            })
          );
        })
      )

      .subscribe();

    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "my-context-menu"))
      .subscribe((nbMenuBag) => {
        if (nbMenuBag.item.title === "Log out") {
          this.logout();
        } else if (nbMenuBag.item.title === "Companiile mele") {
          this.gotoSettings();
          this.contextMenu.hide();
        } else if (nbMenuBag.item.title === "Facturi") {
          this.goToCompanyInvoice();
        } else if (nbMenuBag.item.title === "Management facturi") {
          this.goToEcapInvoiceManagement();
        }
      });
    this.menuService
      .onItemClick()
      .pipe(filter(({ tag }) => tag === "company-context-menu"))
      .subscribe((nbMenuBag) => {
        this.setAsDefaultCompany(nbMenuBag.item.data);
      });

    this.customerDetailService.isLoadingDefaultCompanyObservable
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: boolean) => {
        this.isLoadingDefaultCompany = res;
      });
  }

  gotoSettings() {
    this.router.navigate(["/companies"]);
  }

  goToCompanyInvoice() {
    this.router.navigate(["/ecap-invoice"]);
  }

  goToEcapInvoiceManagement() {
    this.router.navigate(["/ecap-invoice-management"]);
  }

  gotoEdit() {
    this.router.navigate([`/companies/${this.currentSelectedCompanyId}`]);
  }

  setAsDefaultCompany(companyId: number) {
    if (companyId == this.customerDetail.currentCompany.id) return;
    this.customerDetailService
      .setAsDefaultCompany(companyId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        complete: () => this.showMessage("Firma curenta setata!"),
        error: () => this.customerDetailService.updateIsLoadingDefaultCompanyValue(false)
      });

  }

  showMessage(title: string) {
    this.snackBar.open(title, null, {
      duration: 2000,
      panelClass: "error-message-box",
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // change to dark
  enableDarkTheme() {
    this.themeService.changeTheme("dark");
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  toggleSettingsMenu() {
    this.settingsMenuHidden = !this.settingsMenuHidden;
  }
  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  public login() {
    this.authService.login();
  }

  public refreshSession() {
    this.authService.refreshSession();
  }

  public logout() {
    this.authService.logout();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, "settings-sidebar");
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    //this.analyticsService.trackEvent('startSearch');
  }
}
