import { Component, HostBinding, Injector, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { merge, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import { environment } from "../environments/environment";
import { Logger } from "./core/logger.service";
import { I18nService } from "./core/i18n.service";
import { AppInjector } from "./appInjector";

const log = new Logger("App");

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers:[],
})
export class AppComponent implements OnInit {
  @HostBinding("class.tenant") get tenant() {
    return this.isTenant;
  }
  @HostBinding("class.syncap") get syncap() {
    return this.isSyncap;
  }
  @HostBinding("class.ecap") get ecap() {
    return !this.isTenant && !this.isSyncap;
  }

  isAuthorizedSubscription: Subscription | undefined;
  isAuthorized = false;

  favIcon: HTMLLinkElement = document.querySelector("#appIcon");
  isTenant: any;
  isSyncap: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    injector: Injector
  ) {
    AppInjector.setInjector(injector);
  }

  ngOnInit() {
    this.isTenant = !location.href.includes("e-cap.ro") && !location.host.includes("localhost");

    if (this.isTenant) {
      this.favIcon.href = `it-supermarket_favicon.ico`;
      this.titleService.setTitle("IT Supermarket");
    }
    if (location.host.includes("raisis") ) {
      this.favIcon.href = `favicon_raisis.ico`;
      this.titleService.setTitle("Raisis Software Connect");

    }
    if (location.host.includes("qa") ) {
      this.favIcon.href = `syncap_favicon.ico`;
      this.titleService.setTitle("Syncap");
      this.isSyncap = true;
    }
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug("init");

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === "primary"),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        const title = event["title"];
        if (title && !this.isTenant) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }
}
