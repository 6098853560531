import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: `
    <div class="redirect-message">
      <p>Processing authentication...</p>
    </div>
  `,
  styles: [`
    .redirect-message {
      text-align: center;
      margin-top: 50px;
    }
  `]
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Preluăm parametrul "code" din URL-ul de redirecționare
    const authCode = new URLSearchParams(window.location.search).get('code');

    if (authCode) {
      // Trimite codul de autorizare înapoi la fereastra părinte
      window.opener.postMessage(
        {
          authCode: authCode
        },
        'http://localhost:4200' // Asigură-te că originul este corect
      );
    } else {
      console.error('Authorization code not found.');
    }

    // Închidem fereastra popup
    window.close();
  }
}
