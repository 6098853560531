<!-- <app-header></app-header> -->
<!-- <router-outlet></router-outlet> -->

<nb-layout>
  <nb-layout-header fixed>
    <app-header></app-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive >
    <nb-sidebar-header>
      <nb-user *ngIf="customerDetail?.currentCompany?.logoUrl"
               [name]="null"
               picture="{{apiUrl+'/'+customerDetail.currentCompany.logoUrl}}"></nb-user>
    </nb-sidebar-header>
    <nb-menu [items]="menu" *ngIf="menu.length > 0"></nb-menu>
    <div *ngIf="!(menu.length > 0)" styleClass="mt-4">
      <p-skeleton *ngFor="let _ of [].constructor(15)" height="2rem" styleClass="mb-2"></p-skeleton>
    </div>

  </nb-sidebar>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>
  <nb-layout-footer fixed>
    <app-footer></app-footer>
  </nb-layout-footer>
  <nb-sidebar
    class="settings-sidebar"
    tag="settings-sidebar"
    state="collapsed"
    fixed
    [right]="sidebar.id !== 'right'">
    <app-order-notification></app-order-notification>
     <!--<ngx-theme-settings></ngx-theme-settings>-->
  </nb-sidebar>
</nb-layout>
