import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PartnerBase } from '../models/PartnerBase';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EcapCompanyReports } from '../../company/models/ChartDataDto';


@Injectable()
export class ReportsService {

  apiUrl: string

  partners: Map<number,Array<PartnerBase>>;

  ecapReportsSubject = new BehaviorSubject<EcapCompanyReports>(null)
  ecapReportsObservable = this.ecapReportsSubject.asObservable()

  public get ecapReportsValue(){
    return this.ecapReportsSubject.value
  }

  constructor(protected _httpClient: HttpClient,
    @Inject('API_URL') apiUrl: string) { 
      this.apiUrl = apiUrl;

      this.partners = new Map<number,Array<PartnerBase>>();
    }

    syncInvoices(companyErpModuleId: number, companySaleChannelId: number): Observable<any>{
      return this._httpClient.get(this.apiUrl + "/api/Invoice/SyncInvoices",{
        params:{
          companyErpModuleId: companyErpModuleId.toString(),
          companySaleChannelId: companySaleChannelId.toString()
        }
      })
    }

    downloadInvoice(invoiceNumber:string, invoiceSeries: string,companyErpModuleId: number, companySaleChannelId: number): Observable<any>{
      return this._httpClient.get(this.apiUrl + "/api/Invoice/DownloadInvoiceFromReport",{
        params:{
          invoiceSeries: invoiceSeries,
          invoiceNumber: invoiceNumber,
          companyErpModuleId: companyErpModuleId.toString(),
          companySaleChannelId: companySaleChannelId.toString()
        },
        reportProgress: true,
        observe: 'events',
        responseType: 'blob'
      })
    }

    getEcapOrderLinesMapped(dateFrom: Date, dateTo: Date, agentId: string, product: string): Observable<any> {
      return this._httpClient.get(this.apiUrl + "/api/Ecap/GetEcapOrderLinesMapped",{
        params:{
          dateFrom: dateFrom.toDateString(),
          dateTo: dateTo.toDateString(),
          agentId: agentId ,
          product: product
        }
      })
    }

    getEcapReports(): Observable<EcapCompanyReports> {
      return this._httpClient.get<EcapCompanyReports>(this.apiUrl + "/api/Ecap/GetEcapReports").pipe(
        map(ecapReports => {
          this.ecapReportsSubject.next(ecapReports)
          
          return ecapReports
        }),
        catchError(err => of(err))
      );
    }  

    syncEcapReports(): Observable<EcapCompanyReports>{
      return this._httpClient.post<EcapCompanyReports>(this.apiUrl + "/api/Ecap/GenerateEcapReports", {});
    }

    getPartners(companyErpModuleId: number): Observable<any>{
      if ((companyErpModuleId in this.partners) && this.partners[companyErpModuleId].length > 0) {
        return of(this.partners[companyErpModuleId]);
      }

      return this._httpClient.get(this.apiUrl + "/api/Invoice/GetPartners", {
        params:{
          companyErpModuleId: companyErpModuleId.toString()
        }
      }).pipe(
        tap((response: Array<PartnerBase>) => {
          this.partners[companyErpModuleId] = response;
        }));
    }

    saveReport(name: string, config: string, userId: number, companyId: number): Observable<any>{
      return this._httpClient.get(this.apiUrl + "/api/Invoice/SaveReport",{
        params: {
          name: name,
          config: config,
          userId: userId.toString(),
          companyId: companyId.toString()
        }
      })
    }

    deleteReport(id: string): Observable<any>{
      return this._httpClient.delete(this.apiUrl + "/api/Invoice/DeleteReport",{
        params: {
         id: id.toString()
        }
      })
    }


    GetUserSaleReports(userId: number, companyId: number): Observable<any> {
      return this._httpClient.get(this.apiUrl + "/api/Invoice/GetUserSaleReports",{
        params:{
          userId: userId.toString(),
          companyId: companyId.toString()
        }
      })
    }
}
