import { UserCompanyRole } from "./UserCompanyRole";
import { UserSetting } from './UserSetting';

export class User {
  id: number;
  authServerUserId: number;
  name: string;
  email: string;
  agentId: string;
  settings: UserSetting[]
  companyRoles: Array<UserCompanyRole>;

  constructor(data?) {
    data = data || {};
    this.id = data.id || null;
    this.authServerUserId = data.authServerUserId || null;
    this.name = data.name || null;
    this.email = data.email || null;
    this.settings = data.settings
    this.agentId = data.agentId || null;
    this.companyRoles = data.companyRoles || new Array<UserCompanyRole>();
  }
}
