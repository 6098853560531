import { StateService } from "./../services/state.service";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbContextMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbSidebarService,
  NbIconModule,
} from "@nebular/theme";

import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ShellComponent } from "./shell/shell.component";
import { HeaderComponent } from "./shell/header/header.component";
import { FooterComponent } from "./shell/footer/footer.component";
import { RouteReusableStrategy } from "./route-reusable-strategy";
import { SharedModule } from "../shared/shared.module";
import { OrderNotificationModule } from "../shared/order-notification/order-notification.module";
import { MatDividerModule } from "@angular/material/divider";
import { AuthorizationGuard } from "./authentication/authorization.guard";
import { CompanyEditGuard } from "./authentication/company-edit.guard";
import { ShellAuthorizationGuard } from "./authentication/shell-authorization.guard";
import { MatIconModule } from "@angular/material/icon";
import { SkeletonModule } from "primeng/skeleton";
import { ReportsService } from "../reports/service/reports.service";

const NB_MODULES = [
  NbCardModule,
  NbLayoutModule,
  NbIconModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbContextMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NgbModule,
];

const NB_PROVIDERS = [
  ...NbThemeModule.forRoot({
    name: "default",
  }).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
];

@NgModule({
  imports: [
    MatIconModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    SharedModule,
    OrderNotificationModule,
    MatTooltipModule,
    MatDividerModule,
    SkeletonModule,
    ...NB_MODULES,
  ],
  declarations: [HeaderComponent, FooterComponent, ShellComponent],
  providers: [
    ReportsService,
    CompanyEditGuard,
    AuthorizationGuard,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    ShellAuthorizationGuard,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    NbSidebarService,
    StateService,
    ...NB_PROVIDERS,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
