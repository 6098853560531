<div class="content-sidebar" style="overflow: scroll; height: 100%;" #scrollMe [scrollTop]="scrollMe.scrollHeight">
  <div class="title-sidebar">
    Info import comenzi
  </div>
  <div class="notification-trash-icon" *ngIf="orderNotifications.length > 0">
    <span style="flex: 1 1 0%"></span>
    <i class="fa fa-trash" matTooltip="Sterge toate notificarile" (click)="deleteNotifications()"></i>
  </div>

  <div class="notification" *ngFor="let notification of orderNotifications; let i = index;">
    <div class="notification-message">
      <div>
        {{notification.message}}
      </div>
      <span style="flex: 1 1 0%"></span>
      <div class="notification-close-icon">
        <i class="fa fa-times" (click)="deleteNotification(i)"></i>
      </div>
    </div>
    <div *ngIf="notification.logs.length > 0" style="color: #dc7c2a;">
      <!--<div style="text-align:end;">!! Warnings !!</div>-->
      <div *ngFor="let log of notification.logs; let j = index;">
        {{j+1}}. {{log.shortMessage}} <span *ngIf="log.fullMessage.length > 0">: {{log.fullMessage}}</span>
        &nbsp;
        <span *ngIf="log.url != null">
          <a target="_blank"
             href="{{log.url}}">
            Detalii
          </a>
        </span>
      </div>
    </div>
  </div>
</div>
