import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OrderNotificationComponent } from "./component/order-notification.component";
import { MatTooltipModule as MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [CommonModule, MatTooltipModule],
  declarations: [OrderNotificationComponent],
  exports: [OrderNotificationComponent],
})
export class OrderNotificationModule {}
