import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { CustomerDetailService } from "./customer-detail.service";
import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { UserDetail } from "../company/models/UserDetail";
import { catchError, concatMap, map, switchMap, take } from "rxjs/operators";
import _ from "lodash";
import { of } from "rxjs";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnInit, OnDestroy {
  isAuthenticated = false;
  userData: any;
  userMetadata: any;
  companyKey: string;
  userDetail: UserDetail;
  companyId: number;
  isSuperAdmin: boolean;

  constructor(
    private auth: Auth0Service,
    private router: Router,
    private customerDetailService: CustomerDetailService
  ) {
    // for olx authentication
    //if (document.location.href.includes("#login")) {
    //  document.location.href = document.location.href.replace("#login", "");
    //  return;
    //}
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //this.oidcSecurityService.onModuleSetup.unsubscribe();
  }

  setUseMetaData() {
    return this.getUserClaims().pipe(
      map((userData) => {
        this.userData = userData;
        this.userMetadata = _.find(this.userData, (value, key) => key.includes("user_metadata"));

        if (!this.userMetadata) {
          this.router.navigate(["/companies/add"]);
        }

        return userData;
      }),
      take(1)
    );
  }

  getCompanyKey(companyId: number): string {
    let companyKey = null;
    _.each(this.userMetadata, (value, key) => {
      const keyParts = key.split("_");
      if (+keyParts[0] === companyId) {
        companyKey = value;
        return false;
      }
    });

    return companyKey;
  }

  getIsAuthorized(route?: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getIsAuthenticated().pipe(
      concatMap(() => {
        let isAuthorized = false;

        if (this.isSuperAdmin) {
          return of(true);
        }

        const roleIds: number[] = route.data["roles"];

        isAuthorized = this.userDetail?.companyRoles?.some((role) => roleIds.includes(role.id));

        return of(isAuthorized);
      })
    );
  }

  getIsAuthorizedShell(route?: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getIsAuthenticated().pipe(
      concatMap(() => {
        let isAuthorized = false;

        if (this.isSuperAdmin) {
          return of(true);
        }
        isAuthorized = this.userDetail?.companyRoles?.some.length >= 1;

        return of(isAuthorized);
      })
    );
  }

  getIsAuthenticated(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(
      map((isAuthenticated: boolean) => {
        this.isAuthenticated = isAuthenticated;

        this.getUserClaims().subscribe((userData: any) => {
          const userRoles = _.find(userData, (value, key) => key.includes("roles"));

          if (userRoles !== null && userRoles[0] === "SuperAdmin") {
            this.isSuperAdmin = true;
          }
            
        });

        return isAuthenticated;
      }),
      concatMap((isAuthenticated: boolean) => {
        if (isAuthenticated) {
          return this.setUseMetaData();
        }
        return of(isAuthenticated);
      }),
      switchMap(() => {
        if (this.isAuthenticated) {
          
          return this.customerDetailService.getCustomerDetails().pipe(
            map((userDetail: UserDetail) => {
              this.userDetail = userDetail;

              return of(this.isAuthenticated);
            }),
            catchError((err) => {
              return of(err);
            })
          );
        }
        return of(this.isAuthenticated);
      })
    );
  }

  login() {
    this.auth.loginWithRedirect();
  }

  authorizedCallback() {
    //this.auth..authorize();
  }

  refreshSession() {
    // this.oidcSecurityService.authorize();
  }

  logout() {
    this.auth.logout({ returnTo: location.origin });
  }
  getUserData(): Observable<any> {
    return this.auth.user$;
  }
  getUserClaims(): Observable<any> {
    return this.auth.idTokenClaims$;
  }
  getToken(options?: any): Observable<any> {
    return this.auth.getAccessTokenSilently(options);
  }
  authorizeAudience() {
    this.auth.loginWithRedirect({ redirect_uri: location.origin });
  }
  //private doCallbackLogicIfRequired() {
  //  // console.log("location is:" + window.location);
  //  if (typeof location !== "undefined" && window.location.hash) {
  //    // console.log("authorizing the callback ...");
  //    //  this.oidcSecurityService.authorize();

  //    setTimeout(() => {
  //      this.customerDetailService.updateIsLoadingDefaultCompanyValue(true);
  //      this.customerDetailService.getUserDetails().subscribe(
  //        (res) => {
  //          this.customerDetailService.updateIsLoadingDefaultCompanyValue(false);
  //        },
  //        (err) => {
  //          this.customerDetailService.updateIsLoadingDefaultCompanyValue(false);
  //        }
  //      );
  //    }, 2000);
  //  }
  //}
}
