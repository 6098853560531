import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Observable, catchError, filter, map, of } from "rxjs";
import { CustomerDetailService } from "../../services/customer-detail.service";
import { UserDetail } from "../../company/models/UserDetail";

@Injectable({
    providedIn: 'root'
})
export class UserResolverService implements Resolve<any> {
    constructor(private customerDetailService: CustomerDetailService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const customerDetailValue: UserDetail = this.customerDetailService.customerDetailValue

        if(!customerDetailValue){
            return this.customerDetailService.customerDetailObservable.pipe(
                filter(data => data != null),
                catchError(err => of(err))
            )
        }

        return of(customerDetailValue)
    }
}