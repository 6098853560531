import { Injectable, Inject } from "@angular/core";
import { Observable, BehaviorSubject, of } from "rxjs";
import { catchError, concatMap, map, switchMap, take } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UserDetail } from "../company/models/UserDetail";
import { Router } from "@angular/router";
import { CommonService } from "./common.service";
import { CompanyModuleTypes } from "../company/models/Enums";
import { AuthService as Auth0Service } from "@auth0/auth0-angular";
import { ProtectData } from "../core/protectData/protectData";
import _ from "lodash";
import { UserSetting } from "../company/models/UserSetting";
import { ReportsService } from "../reports/service/reports.service";
import { CompanyStockModule } from "../company/models/modules/companyStockModule";
import { CompanyFeedInModule } from "../company/models/modules/companyFeedInModule";
import { CompanyFeedOutModule } from "../company/models/modules/companyFeedOutModule";

@Injectable()
export class CustomerDetailService {
  apiUrl: string;
  isLoadedCustomerDetail: boolean = false;
  userDetails: UserDetail;
  userKey: string

  constructor(
    private _httpClient: HttpClient,
    private commonService: CommonService,
    private auth: Auth0Service,
    private router: Router,
    public reportsService:ReportsService,
    @Inject("API_URL") apiUrl: string
  ) {
    this.apiUrl = apiUrl;
  }

  loadedCustomerSubject = new BehaviorSubject<UserDetail>(null);
  loadedCustomerObservable = this.loadedCustomerSubject.asObservable();

  customerDetailSubject = new BehaviorSubject<UserDetail>(null);
  customerDetailObservable = this.customerDetailSubject.asObservable();

  shellMenuSubject = new BehaviorSubject<UserDetail>(null);
  shellMenuObservable = this.shellMenuSubject.asObservable();

  public get customerDetailValue(): UserDetail {
    return this.customerDetailSubject.value
  }

  private isLoadingDefaultCompanySubject = new BehaviorSubject<boolean>(false);
  isLoadingDefaultCompanyObservable = this.isLoadingDefaultCompanySubject.asObservable();

  getUserDetails(useCache = true): Observable<UserDetail> {
    
    return this.userDetails && useCache
    ? of(this.userDetails)
    : this._httpClient.get(this.apiUrl + "/customer/customer-details").pipe(
      switchMap((userDetails: UserDetail) => {
        if (userDetails == null) return null;
        this.loadedCustomerSubject.next(userDetails)
        return of(userDetails)
      }),
      concatMap(userDetails => {
        return this.getCustomerData(userDetails)
      })
    );
  }

  getCustomerDetails(useCache: boolean = true){
    return this.userDetails && useCache
    ? of(this.userDetails)
    : this._httpClient.get(this.apiUrl + "/customer/customer-details").pipe(
      map((userDetails: UserDetail) => {
        if (userDetails == null) return null;

        this.loadedCustomerSubject.next(userDetails)
        
        this.getCustomerData(userDetails).pipe(
          map(res => res)
        ).subscribe()

        return userDetails;
      })
    );
  }

  getCustomerDetailsData(): Observable<any>{
    return this._httpClient.get<any>(this.apiUrl + "/customer/customer-data")
  }

  getCustomerData(userDetails: UserDetail){
    return this.auth.idTokenClaims$.pipe(
      take(1),
      switchMap(userData => {
        const userMetadata = _.find(userData, (value, key) => key.includes("user_metadata"));
        const result = userDetails
        let customerDataObservable$ = new Observable<UserDetail>(null)
        
        _.each(userMetadata, (value, key) => {
          const keyParts = key.split("_");
          if (+keyParts[0] === result.currentCompany.id) {
            this.userKey = value
            customerDataObservable$ = this.getCustomerDetailsData().pipe(
              map(companyData => {

                for (let i = 0; i < companyData.translateModules.length; i++) {
                  let translateModule = companyData.translateModules[i];
                  translateModule.settingValues.forEach((translateSetting) => {
                    translateSetting.value = ProtectData.unProtect(value, translateSetting.value);
                  });
                }
      
                result.currentCompany.saleChannelModules     = companyData.saleChannelModules
                result.currentCompany.erpModules             = companyData.erpModules
                result.currentCompany.courierModules         = companyData.courierModules
                result.currentCompany.incomeModules          = companyData.incomeModules
                result.currentCompany.translateModules       = companyData.translateModules
                result.currentCompany.emagFulfillmentModules = companyData.emagFulfillmentModules
                result.currentCompany.wmsModules             = companyData.wmsModules
                result.currentCompany.marketingModules       = companyData.marketingModules
      
                let data = new UserDetail(result);
                
                this.customerDetailSubject.next(data);
                this.shellMenuSubject.next(data)

                this.isLoadedCustomerDetail = true;
                this.userDetails = data;
                
                return data
              }),
            )
          
          }
        })
        
        return customerDataObservable$
      })
    )
  }
  

  //daca settings este gol sau null o sa aduca toate setarile
  getCompanyCourierModulesResources(settings: string[] = []){
    const companyCourierModules = this.customerDetailValue.currentCompany.courierModules

    const missingCourierModulesSettingsIds = []

    companyCourierModules.forEach(courierModule => {
      const currentCourierModule = companyCourierModules.find(x => x.id == courierModule.id)

      const missingSettings = settings.length > 0 ?
        settings.reduce((missing, name) => {
          let setting = currentCourierModule.settingValues?.find(x => x.name === name);

          if (!setting) {
            missing.push(name);
          }

          return missing;
        }, [])
        : [];
        
      if(courierModule.settingValues?.length == 0 || missingSettings?.length > 0){
        missingCourierModulesSettingsIds.push(courierModule.id)
      }
    })
    
    return missingCourierModulesSettingsIds.length > 0 ?
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanyCouriersModulesResources",
        {
          params: {
            courierModulesIds: missingCourierModulesSettingsIds.join(","),
            settings: settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyCourierModules => {
          const customerDetail = this.customerDetailValue

          for(let companyCourierModule of companyCourierModules){
            companyCourierModule.settingValues.forEach((courierModuleSetting) => {
              courierModuleSetting.value = ProtectData.unProtect(this.userKey, courierModuleSetting.value);
            });
  
            const courierModule = customerDetail.currentCompany.courierModules.find(x => x.id == companyCourierModule.id)
            courierModule.settingValues = companyCourierModule.settingValues
          }
          
          this.customerDetailSubject.next(customerDetail)

          return of(customerDetail.currentCompany.courierModules)
        })
      ) : of(companyCourierModules)
  }

  getCompanyCourierModuleResource(companyCourierModuleId: number, settings: string[] = []){
    const currentCourierModule = this.customerDetailValue.currentCompany.courierModules.find(x => x.id == companyCourierModuleId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCourierModule.settingValues?.find(x => x.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentCourierModule) : 
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanyCourierModuleResource",
        {
          params: {
            companyCourierModuleId: companyCourierModuleId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyCourierModule => {
          companyCourierModule.settingValues.forEach((courierModuleSetting) => {
            courierModuleSetting.value = ProtectData.unProtect(this.userKey, courierModuleSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const currentCourierModule = customerDetail.currentCompany.courierModules.find(x => x.id == companyCourierModule.id)
          
          currentCourierModule.settingValues.push(...companyCourierModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentCourierModule)
        })
      )
  }

  getCompanyEmagFulfillmentModuleResource(companyEmagFulfillmentId: number, settings: string[] = []){
    const currentEmagFulfillmentModule = this.customerDetailValue.currentCompany.emagFulfillmentModules.find(x => x.id == companyEmagFulfillmentId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentEmagFulfillmentModule.settingValues?.find(x => x.setting.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentEmagFulfillmentModule) : 
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanyEmagFulfillmentModuleResource",
        {
          params: {
            companyEmagFulfillmentModuleId: companyEmagFulfillmentId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyEmagFulfillmentModule => {
          companyEmagFulfillmentModule.settingValues.forEach((emagFulfillmentModuleSetting) => {
            emagFulfillmentModuleSetting.value = ProtectData.unProtect(this.userKey, emagFulfillmentModuleSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const currentEmagFulfillmentModule = customerDetail.currentCompany.emagFulfillmentModules.find(x => x.id == companyEmagFulfillmentModule.id)
          
          currentEmagFulfillmentModule.settingValues.push(...companyEmagFulfillmentModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentEmagFulfillmentModule)
        })
      )
  }

  //daca settings este gol sau null o sa aduca toate setarile
  getCompanySalesChannnelModulesResources(settings: string[] = []){
    const companySalesChannels = this.customerDetailValue.currentCompany.saleChannelModules

    const missingSalesChannelsSettingsIds = []

    companySalesChannels.forEach(saleChannel => {
      const currentCompanySaleChannel = companySalesChannels.find(x => x.id == saleChannel.id)
    
      const missingSettings = settings.length > 0 ?
        settings.reduce((missing, name) => {
          let setting = currentCompanySaleChannel.settingValues?.find(x => x.name === name);

          if (!setting) {
            missing.push(name);
          }

          return missing;
        }, [])
        : [];

      if(saleChannel.settingValues?.length == 0 || missingSettings?.length > 0){
        missingSalesChannelsSettingsIds.push(saleChannel.id)
      }
    })
  
    
    return missingSalesChannelsSettingsIds.length > 0 ?
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanySalesChannnelsModulesResources",
        {
          params: {
            salesChannelsIds: missingSalesChannelsSettingsIds.join(","),
            settings: settings.join(",")
          }
        }
      ).pipe(
        switchMap(companySaleChannelModules => {
          const customerDetail = this.customerDetailValue

          for(let companySaleChannelModule of companySaleChannelModules){
            companySaleChannelModule.settingValues.forEach((saleChannelSetting) => {
              saleChannelSetting.value = ProtectData.unProtect(this.userKey, saleChannelSetting.value);
            });
  
            const companySaleChannel = customerDetail.currentCompany.saleChannelModules.find(x => x.id == companySaleChannelModule.id)
            
            companySaleChannel.settingValues = companySaleChannelModule.settingValues
          }
          
          this.customerDetailSubject.next(customerDetail)

          return of(customerDetail.currentCompany.saleChannelModules)
        })
      ) : of(companySalesChannels)
  }

  getCompanySalesChannnelModuleResource(companySaleChannelId: number, settings: string[] = []){

    const currentCompanySaleChannel = this.customerDetailValue.currentCompany.saleChannelModules.find(x => x.id == companySaleChannelId)
    
    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCompanySaleChannel.settingValues?.find(x => x.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];
    
    return missingSettings.length == 0 ? of(currentCompanySaleChannel) :
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanySalesChannnelModuleResource",
        {
          params: {
            companySaleChannelId: companySaleChannelId,
            settings: missingSettings.join(",")
          }
        }
      ).pipe(
        switchMap(companySaleChannelModule => {
          companySaleChannelModule.settingValues.forEach((saleChannelSetting) => {
            saleChannelSetting.value = ProtectData.unProtect(this.userKey, saleChannelSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const companySaleChannel = customerDetail.currentCompany.saleChannelModules.find(x => x.id == companySaleChannelModule.id)
          
          companySaleChannel.settingValues.push(...companySaleChannelModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(companySaleChannel)
        })
      )
  }

  getCompanyErpModuleResource(companyErpModuleId: number, settings: string[] = []){
    const currentCompanyErp = this.customerDetailValue.currentCompany.erpModules.find(x => x.id == companyErpModuleId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCompanyErp.settingValues?.find(x => x.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentCompanyErp) : 
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanyErpModuleResource",
        {
          params: {
            companyErpModuleId: companyErpModuleId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyErpModule => {
          companyErpModule.settingValues.forEach((erpModuleSetting) => {
            erpModuleSetting.value = ProtectData.unProtect(this.userKey, erpModuleSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const currentCompanyErp = customerDetail.currentCompany.erpModules.find(x => x.id == companyErpModule.id)
          
          currentCompanyErp.settingValues.push(...companyErpModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentCompanyErp)
        })
      )
  }

  getCompanyStockModuleResource(companyStockModuleId: number, settings: string[] = []){
    const currentCompanyStock = this.customerDetailValue.currentCompany.stockModules.find(x => x.id == companyStockModuleId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCompanyStock.settingValues?.find(x => x.setting.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentCompanyStock) : 
      this._httpClient.get<CompanyStockModule>(this.apiUrl + "/customer/GetCompanyStockModuleResource",
        {
          params: {
            companyStockModuleId: companyStockModuleId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyStockModule => {
          companyStockModule.settingValues.forEach((stockModuleSetting) => {
            stockModuleSetting.value = ProtectData.unProtect(this.userKey, stockModuleSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const currentCompanyStock = customerDetail.currentCompany.stockModules.find(x => x.id == companyStockModule.id)
          
          currentCompanyStock.settingValues.push(...companyStockModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentCompanyStock)
        })
      )
  }

  getCompanyFeedInModuleResource(companyFeedInModuleId: number, settings: string[] = []): Observable<CompanyFeedInModule>{
    const currentCompanyFeedIn = this.customerDetailValue.currentCompany.feedInModules.find(x => x.id == companyFeedInModuleId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCompanyFeedIn.settingValues?.find(x => x.setting.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentCompanyFeedIn) : 
      this._httpClient.get<CompanyFeedInModule>(this.apiUrl + "/customer/GetCompanyFeedInModuleResource",
        {
          params: {
            companyFeedInModuleId: companyFeedInModuleId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyFeedInModule => {
          // companyFeedInModule.settingValues.forEach((feedInModuleSetting) => {
          //   feedInModuleSetting.value = ProtectData.unProtect(this.userKey, feedInModuleSetting.value);
          // });

          const customerDetail = this.customerDetailValue
          const currentCompanyFeedIn = customerDetail.currentCompany.feedInModules.find(x => x.id == companyFeedInModule.id)
          
          currentCompanyFeedIn.settingValues.push(...companyFeedInModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentCompanyFeedIn)
        })
      )
  }

  getCompanyFeedOutModuleResource(companyFeedOutModuleId: number, settings: string[] = []): Observable<CompanyFeedOutModule>{
    const currentCompanyFeedOut = this.customerDetailValue.currentCompany.feedOutModules.find(x => x.id == companyFeedOutModuleId)

    const missingSettings = settings.length > 0 ?
      settings.reduce((missing, name) => {
        let setting = currentCompanyFeedOut.settingValues?.find(x => x.setting.name === name);

        if (!setting) {
          missing.push(name);
        }

        return missing;
      }, [])
      : [];

    return missingSettings.length == 0 ? of(currentCompanyFeedOut) : 
      this._httpClient.get<CompanyFeedOutModule>(this.apiUrl + "/customer/GetCompanyFeedOutModuleResource",
        {
          params: {
            companyFeedOutModuleId: companyFeedOutModuleId,
            settings : settings.join(",")
          }
        }
      ).pipe(
        switchMap(companyFeedOutModule => {
          companyFeedOutModule.settingValues.forEach((feedOutModuleSetting) => {
            feedOutModuleSetting.value = ProtectData.unProtect(this.userKey, feedOutModuleSetting.value);
          });

          const customerDetail = this.customerDetailValue
          const currentCompanyFeedOut = customerDetail.currentCompany.feedOutModules.find(x => x.id == companyFeedOutModule.id)
          
          currentCompanyFeedOut.settingValues.push(...companyFeedOutModule.settingValues)

          this.customerDetailSubject.next(customerDetail)

          return of(currentCompanyFeedOut)
        })
      )
  }

  getCompanyStockModulesResources(){
    const companyStocks = this.customerDetailValue.currentCompany.stockModules

    const missingStocksSettingsIds = []

    companyStocks.forEach(stock => {
      if(stock.settingValues?.length == 0){
        missingStocksSettingsIds.push(stock.id)
      }
    })
  
    
    return missingStocksSettingsIds.length > 0 ?
      this._httpClient.get<any>(this.apiUrl + "/customer/GetCompanyStockModulesResources").pipe(
        switchMap(companyStockModules => {
          const customerDetail = this.customerDetailValue

          for(let companyStockModule of companyStockModules){
            // companyStockModule.settingValues.forEach((stockSetting) => {
            //   stockSetting.value = ProtectData.unProtect(this.userKey, stockSetting.value);
            // });
  
            const companyStock = customerDetail.currentCompany.stockModules.find(x => x.id == companyStockModule.id)
            
            companyStock.settingValues = companyStockModule.settingValues
          }
          
          this.customerDetailSubject.next(customerDetail)

          return of(customerDetail.currentCompany.stockModules)
        })
      ) : of(companyStocks)
  }

  updateUserSettings(userSettings: UserSetting[]): Observable<any> {
    const cleanedUserSettings = userSettings.map(setting => {
      // Filter out properties with null values
      const cleanedSetting: any = {};
      Object.keys(setting).forEach(key => {
        if (setting[key] !== null) {
          cleanedSetting[key] = setting[key];
        }
      });
      return cleanedSetting;
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this._httpClient.put<any>(`${this.apiUrl}/customer/UpdateUserSettings`, cleanedUserSettings, { headers: headers })
      .pipe(
        catchError(error => {

          return of(error)
        })
      );
  }

  deleteUserSetting(settingId:number){
    return this._httpClient.delete<any>(`${this.apiUrl}/customer/DeleteUserSetting`, {
      params: {
        id: settingId
      }
    })
  }

  getUserSettings(): Observable<any> {
   
    return this._httpClient.get<any>(`${this.apiUrl}/customer/settings`)
      .pipe(
        catchError(error => {

          return of(error)
        })
      );
  }

  setAsDefaultCompany(companyId: number) {
    this.updateIsLoadingDefaultCompanyValue(true);
    return this._httpClient
      .put(
        this.apiUrl + "/company/current",
        {},
        {
          params: {
            companyId: companyId.toString(),
          },
        }
      )
      .pipe(
        map((cRes) => {
          if (!this.router.url.includes("/company/")) {
            this.router.navigate(["/home"]);
          }
          this.commonService.startOrStopSignalRConnectionEventEmitter.emit(false);
          this.commonService.resetDataFromCacheEventEmitter.emit(true);
          return cRes;
        }),
        concatMap((res) => {
          return this.getUserDetails(false).pipe(
            map((result) => {
              // this.commonService.startOrStopSignalRConnectionEventEmitter.emit(true);
              // this.updateIsLoadingDefaultCompanyValue(false);
              return result;
            })
          );
        }),
        concatMap((res) => {
          return this.reportsService.getEcapReports().pipe(
            map((result) => {
              this.commonService.startOrStopSignalRConnectionEventEmitter.emit(true);
              this.updateIsLoadingDefaultCompanyValue(false);
              return res;
            })
          );
        }),
      );
  }

  updateIsLoadingDefaultCompanyValue(value: boolean) {
    this.isLoadingDefaultCompanySubject.next(value);
  }

  isModuleActive(moduleName: string, type: string): boolean {
    let res: boolean = false;
    let customerDetail: UserDetail = this.customerDetailValue;

    if (customerDetail != null) {
      let currentDate: Date = new Date();
      switch (type) {
        case CompanyModuleTypes.SaleChannel: {
          res =
            customerDetail.currentCompany.saleChannelModules.find(
              (c) => c.type == moduleName && (c.expirationDate == null || c.expirationDate >= currentDate)
            ) != null;
          break;
        }
        case CompanyModuleTypes.Courier: {
          res =
            customerDetail.currentCompany.courierModules.find(
              (c) => c.type == moduleName && (c.expirationDate == null || c.expirationDate >= currentDate)
            ) != null;
          break;
        }
        case CompanyModuleTypes.Erp: {
          res =
            customerDetail.currentCompany.erpModules.find(
              (c) => c.type == moduleName && (c.expirationDate == null || c.expirationDate >= currentDate)
            ) != null;
          break;
        }
        case CompanyModuleTypes.Marketing: {
          res =
            customerDetail.currentCompany.marketingModules.find(
              (c) => c.type == moduleName && (c.expirationDate == null || c.expirationDate >= currentDate)
            ) != null;
          break;
        }
      }
    }
    return res;
  }

  // This method can be called a couple of different ways
  // *hasModule="'moduleName'"  // Assumes claimValue is true
  // *hasModule="['moduleName1','moduleName2']"
  hasModule(value: any) {
    let ret: boolean = false;

    // See if an array of values was passed in.
    if (typeof value === "string") {
      ret = this.isModuleValid(value);
    } else {
      let modules: string[] = value;
      if (modules) {
        for (let index = 0; index < modules.length; index++) {
          ret = this.isModuleValid(modules[index]);
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }
    return ret;
  }

  private isModuleValid(value: string): boolean {
    //let ret: boolean = false;
    //let ccr: UserDetail = null;
    //let moduleName: string; //word[0]
    //let customerRoleId: number; //word[1]
    //let companyId: number; //word[2]

    //ccr = this.customerDetailValue;
    //if (ccr) {
    //  let words = value.split(":");
    //  if (words.length == 1) {
    //    moduleName = words[0];
    //  } else if (words.length == 2) {
    //    moduleName = words[0].toLowerCase();
    //    if (words[1].length > 0) {
    //      customerRoleId = +words[1];
    //    }
    //  } else if (words.length == 3) {
    //    moduleName = words[0].toLowerCase();
    //    if (words[1].length > 0) {
    //      customerRoleId = +words[1];
    //    }
    //    if (words[2].length > 0) {
    //      companyId = +words[2];
    //    }
    //  }

    //  if (words.length == 1) {
    //    ret = ccr.companyModules.find((c) => c.toLowerCase() == moduleName.toLocaleLowerCase()) != null;
    //  } else if (words.length == 2) {
    //    ret =
    //      ccr.companyModules.find(
    //        (c) => c.toLowerCase() == moduleName.toLocaleLowerCase() && ccr.companyRoles.id == customerRoleId
    //      ) != null;
    //  } else if (words.length == 3) {
    //    if (customerRoleId != undefined) {
    //      ret =
    //        ccr.companyModules.find(
    //          (c) =>
    //            c.toLowerCase() == moduleName.toLocaleLowerCase() &&
    //            ccr.companyRoles.id == customerRoleId &&
    //            ccr.currentCompany.id == companyId
    //        ) != null;
    //    } else {
    //      ret =
    //        ccr.companyModules.find(
    //          (c) => c.toLowerCase() == moduleName.toLocaleLowerCase() && ccr.currentCompany.id == companyId
    //        ) != null;
    //    }
    //  }
    //}
    return true;
  }
}
