import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";


export enum SnackBarNotificationType {
  Success = "Success",
  Warning = "Warning",
  Error = "Error",
}

@Injectable()
export class SnackBarNotificationService {
  constructor(private _matSnackBar: MatSnackBar) {}

  showMessage(type: SnackBarNotificationType, message: string, miliSeconds: number = 0, status?: string) {
    let className = "";
    switch (type) {
      case SnackBarNotificationType.Success: {
        className = "snackbar-success";
        break;
      }
      case SnackBarNotificationType.Warning: {
        className = "snackbar-warning";
        break;
      }
      case SnackBarNotificationType.Error: {
        className = "snackbar-error";
        break;
      }
    }
    
    this._matSnackBar.open(message, status != null ? status : type,{
      panelClass: [className],
      verticalPosition: "top",
      duration: miliSeconds,
    });
  }
}
