import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../services/auth.service";

@Injectable()
export class ShellAuthorizationGuard implements CanActivate {
  // Very important to keep authService injected into constructor here,
  // because when you refresh a page this guard will return false even if user is logged and has the token in localstorage
  // If authService is injected, authService is first instantiated and configured to work properly
  // If authService is NOT injected, authService will be instantieted and configured later, so at this moment will return false
  // Another approach is to delete authService and make all the configuration in AppModule, check this https://github.com/damienbod/angular-auth-oidc-client
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.getIsAuthorizedShell(route).pipe(
      map((isAuthorized: boolean) => {
        if (!isAuthorized) {
          this.authService.login();

          return false
        }
        return true;
      })
    );
  }
}
