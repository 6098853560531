// `.env.json` is generated by the `npm run build` command
import * as env from "./.env.json";

export const environment = {
  production: true,
  defaultLanguage: "ro-RO",
  clientId: "uGgfgtwtRQ9snUpgK8XMGraoMAhf1VaC",
  identityUrl: "login.e-cap.ro",
  apiUrl: "https://qa-api.e-cap.ro",
  clientUrl: "https://qa.e-cap.ro",
  olxRedirectUri: "https://qa.e-cap.ro/home",
  supportedLanguages: ["en-US", "fr-FR", "ro-RO"],  
  audience: "https://ecap.eu.auth0.com/api/v2/"
};

