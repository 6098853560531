import { CompanyModuleResource } from "./CompanyModuleResource";
import { StockMethodEnum } from "./Enums";
import { CompanyEmagFulfillmentModule } from "./modules/companyEmagFulfillmentModule";
import { CompanyFeedInModule } from "./modules/companyFeedInModule";
import { CompanyFeedOutModule } from "./modules/companyFeedOutModule";
import { CompanyFulfillmentModule } from "./modules/companyFulfillmentModule";
import { CompanyImportInvoiceModuleDto } from "./modules/CompanyImportInvoiceModuleDto";
import { CompanyIncomeModule } from "./modules/companyIncomeModule";
import { CompanyInvoiceModule } from "./modules/companyInvoiceModule";
import { CompanyPriceDetectorModule } from "./modules/companyPriceDetectorModule";
import { CompanyStockModule } from "./modules/companyStockModule";
import { CompanyWmsModule } from "./modules/companyWmsModule";

export class Company {
  id: number;
  name: string;
  cui: string;
  nrOrdRegCom: string;
  sediu: string;
  judet: string;
  telefon: string;
  email: string;
  sendInvoiceTo: string;
  stockMethod: StockMethodEnum;
  logo: string;
  isPartner: boolean;
  stamp: string;
  logoUrl: string;
  stampUrl: string;
  erpModules: Array<CompanyModuleResource>;
  marketingModules: Array<CompanyModuleResource>;
  crmModules: Array<CompanyModuleResource>;
  saleChannelModules: Array<CompanyModuleResource>;
  courierModules: Array<CompanyModuleResource>;
  translateModules: Array<CompanyModuleResource>;
  emagFulfillmentModules: Array<CompanyEmagFulfillmentModule>;
  stockModules: Array<CompanyStockModule>;
  feedInModules: Array<CompanyFeedInModule>;
  feedOutModules: Array<CompanyFeedOutModule>;
  invoiceModule: CompanyInvoiceModule;
  fulfillmentModules: Array<CompanyFulfillmentModule>;
  priceDetectorModules: Array<CompanyPriceDetectorModule>;
  incomeModules: Array<CompanyIncomeModule>;
  importInvoiceModules: Array<CompanyImportInvoiceModuleDto>;
  wmsModules: Array<CompanyWmsModule>;
  activeCompanyErpModuleId: number;
  primaryCompanySaleChannelModuleId: number;
  primaryCompanyCourierModuleId: number;
  primaryCompanyTranslateModuleId: number
  primaryCompanyWmsModuleId: number
  companyOwnerUserId: number;
  maxDueDays: number;
  downloadPickOrder: boolean;
  separatePdfFiles: boolean;
  lotControlled: boolean;
  generateAwbFirst: boolean;
  awbFormat: string;
  packageType: string;
  productAwbInfoType: string;
  company: string;
  clientIdentifier:string;
  constructor(data?) {
    data = data || {};
    this.id = data.id || null;
    this.name = data.name || null;
    this.cui = data.cui || null;
    this.nrOrdRegCom = data.nrOrdRegCom || null;
    this.sediu = data.sediu || null;
    this.judet = data.judet || null;
    this.telefon = data.telefon || null;
    this.email = data.email || null;
    this.isPartner = data.isPartner || false;
    this.downloadPickOrder = data.downloadPickOrder || false;
    this.separatePdfFiles = data.separatePdfFiles || false;
    this.sendInvoiceTo = data.sendInvoiceTo || null;
    this.stockMethod = data.stockMethod || null;
    this.awbFormat = data.awbFormat || null;
    this.packageType = data.packageType || null;
    this.lotControlled = data.lotControlled || false;
    this.generateAwbFirst = data.generateAwbFirst || false;
    this.productAwbInfoType = data.productAwbInfoType || null;
    this.logo = data.logo || null;
    this.logoUrl = data.logoUrl || null;
    this.stamp = data.stamp || null;
    this.activeCompanyErpModuleId = data.activeCompanyErpModuleId;
    this.primaryCompanySaleChannelModuleId = data.primaryCompanySaleChannelModuleId;
    this.primaryCompanyCourierModuleId = data.primaryCompanyCourierModuleId;
    this.primaryCompanyWmsModuleId = data.primaryCompanyWmsModuleId
    this.companyOwnerUserId = data.companyOwnerUserId;
    this.maxDueDays = data.maxDueDays;
    this.clientIdentifier = data.clientIdentifier;
    this.stockModules = data.stockModules || [];

    this.incomeModules = data.incomeModules || [];

    this.importInvoiceModules = data.importInvoiceModules || [];

    this.emagFulfillmentModules = data.emagFulfillmentModules || [];

    if (data.invoiceModule != null) {
      this.invoiceModule = data.invoiceModule;
      this.invoiceModule.expirationDate =
        this.invoiceModule.expirationDate != null ? new Date(this.invoiceModule.expirationDate) : null;
    }

    this.fulfillmentModules = data.fulfillmentModules || [];
    this.fulfillmentModules.forEach((fm) => {
      fm.expirationDate = fm.expirationDate != null ? new Date(fm.expirationDate) : null;
    });

    this.erpModules = new Array<CompanyModuleResource>();
    if (data.erpModules != null) {
      data.erpModules.forEach((item) => {
        this.erpModules.push(new CompanyModuleResource(item));
      });
    }

    this.marketingModules = new Array<CompanyModuleResource>();
    if (data.marketingModules != null) {
      data.marketingModules.forEach((item) => {
        this.marketingModules.push(new CompanyModuleResource(item));
      });
    }

    this.saleChannelModules = new Array<CompanyModuleResource>();
    if (data.saleChannelModules != null) {
      data.saleChannelModules.forEach((item) => {
        this.saleChannelModules.push(new CompanyModuleResource(item));
      });
    }

    this.courierModules = new Array<CompanyModuleResource>();
    if (data.courierModules != null) {
      data.courierModules.forEach((item) => {
        this.courierModules.push(new CompanyModuleResource(item));
      });
    }

    this.translateModules = new Array<CompanyModuleResource>();
    if (data.translateModules != null) {
      data.translateModules.forEach((item) => {
        this.translateModules.push(new CompanyModuleResource(item));
      });
    }

    this.feedInModules = new Array<CompanyFeedInModule>();
    if (data.feedInModules != null) {
      data.feedInModules.forEach((item) => {
        this.feedInModules.push(item);
      });
    }

    this.feedOutModules = new Array<CompanyFeedOutModule>();
    if (data.feedOutModules != null) {
      data.feedOutModules.forEach((item) => {
        this.feedOutModules.push(item);
      });
    }

    this.priceDetectorModules = new Array<CompanyPriceDetectorModule>();
    if (data.priceDetectorModules != null) {
      data.priceDetectorModules.forEach((item) => {
        this.priceDetectorModules.push(item);
      });
    }

    this.wmsModules = new Array<CompanyWmsModule>();
    if (data.wmsModules != null) {
      data.wmsModules.forEach((item) => {
        this.wmsModules.push(item);
      });
    }
  }

  getSaleChannelSettingValueBySettingName(type: string, settingName: string): string {
    let value = null;
    let indexType = this.saleChannelModules.findIndex((x) => x.type == type);
    if (indexType >= 0) {
      let settingValue = this.saleChannelModules[indexType].settingValues.find((x) => x.name == settingName);
      if (settingValue != null) {
        value = settingValue.value;
      }
    }

    return value;
  }

  getErpSettingValueBySettingName(type: string, settingName: string): string {
    let value = null;
    let indexType = this.saleChannelModules.findIndex((x) => x.type == type);
    if (indexType >= 0) {
      let settingValue = this.saleChannelModules[indexType].settingValues.find((x) => x.name == settingName);
      if (settingValue != null) {
        value = settingValue.value;
      }
    }

    return value;
  }
}
