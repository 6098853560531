import { Injectable, EventEmitter, Inject } from "@angular/core";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { CommonService } from "./common.service";
import { BulkOrderNotificationSignalR, OrderNotificationSignalR } from "../company/models/OrderNotificationSignalR";
import { SnackBarNotificationService, SnackBarNotificationType } from "./snackBar-notification.service";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { SyncProductsNotificationSignalR } from "../company/models/SyncSaleChannelPricesNotificationSignalR";
import { Log } from "../company/models/Log";
import { environment } from "../../environments/environment";
import { LogApplication } from "../core/models/enums/logApplication";

@Injectable()
export class SignalRService {
  apiUrl: string;
  orderNotification = new EventEmitter<OrderNotificationSignalR>();
  bulkOrderNotification = new EventEmitter<BulkOrderNotificationSignalR>();
  importExcelProductNotification = new EventEmitter<SyncProductsNotificationSignalR>();
  exportExcelProductNotification = new EventEmitter<SyncProductsNotificationSignalR>();
  syncSaleChannelPricesNotification = new EventEmitter<SyncProductsNotificationSignalR>();
  logNotification = new EventEmitter<Log>();

  private isConnectionAlive = false;
  private _hubConnection: HubConnection;
  private numberOfFails: number = 0;

  constructor(
    @Inject("API_URL") apiUrl: string,
    private authService: AuthService,
    private commonService: CommonService,
    private snackBarNotificationService: SnackBarNotificationService
  ) {
    this.apiUrl = apiUrl;
    this.resetSignalRConnection();
    this.authService
      .getToken()
      .pipe(
        map((token) => {
          this.startSignalRService(token);
          return token;
        })
      )
      .subscribe();
  }

  isConnectionAliveWithServer(): boolean {
    return this.isConnectionAlive;
  }

  sendMessage(message: string) {
    this._hubConnection.invoke("NewMessage", message);
  }

  private createConnection(accessToken) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.apiUrl + "/orderhub", {
        accessTokenFactory: () => accessToken,
      })
      .build();

    // this._hubConnection.serverTimeoutInMilliseconds = 60000;
    // this._hubConnection.keepAliveIntervalInMilliseconds = 50000;
  }

  private startConnection(): void {
    this.isConnectionAlive = false;
    this._hubConnection
      .start()
      .then(() => {
        this.isConnectionAlive = true;
        this.numberOfFails = 0;
      })
      .catch((err) => {
        this.isConnectionAlive = false;
        setTimeout(() => {
          this.numberOfFails += 1;
          if (this.numberOfFails <= 3) {
            this.startConnection();
          } else {
            this.snackBarNotificationService.showMessage(
              SnackBarNotificationType.Error,
              "Nu reusesc sa stabilesc conexiunea cu serverul",
              0,
              "SignalR"
            );
          }
        }, 5000);
      });
  }

  private stopConnection(): void {
    this.isConnectionAlive = false;
    this._hubConnection.stop();
  }

  onConnectionClose() {
    this._hubConnection.onclose(() => {
      if (this.isConnectionAlive == true) {
        // means that the connection with the server has been lost, and was not intentionally stopped
        // we need to reestablish the connection
        this.startConnection();
      }
    });
  }

  private orderNotificationEvents(): void {
    this._hubConnection.on("OrderImported", (notification: OrderNotificationSignalR) => { 
      this.snackBarNotificationService.showMessage(
        notification.logs.length > 0 ? SnackBarNotificationType.Warning : SnackBarNotificationType.Success,
        notification.message,
        10000);

      notification.logs = this.prepareLogsUrl(notification.logs);
      this.orderNotification.emit(notification);
    });
  }

  private bulkOrderNotificationEvents(): void {
    this._hubConnection.on("BulkOrderImported", (notification: BulkOrderNotificationSignalR) => { 
      this.snackBarNotificationService.showMessage(SnackBarNotificationType.Success,
        notification.message,
        10000);

      this.bulkOrderNotification.emit(notification);
    });
  }

  private wmsOrderAssignedNotificationEvents(): void {
    this._hubConnection.on("OrderAssigned", (notification: OrderNotificationSignalR) => {
      this.snackBarNotificationService.showMessage(
        notification.logs.length > 0 ? SnackBarNotificationType.Warning : SnackBarNotificationType.Success,
        notification.message,
        10000);

      notification.logs = this.prepareLogsUrl(notification.logs);
      this.orderNotification.emit(notification);
    });
  }

  private wmsOrderTakenNotificationEvents(): void {
    this._hubConnection.on("OrderTaken", (notification: OrderNotificationSignalR) => {
      this.orderNotification.emit(notification);
    });
  }

  private importExcelProductNotificationEvents(): void {
    this._hubConnection.on("ImportExcelProductLogAction", (data: SyncProductsNotificationSignalR) => {
      let syncMessage = new SyncProductsNotificationSignalR(data);
      this.importExcelProductNotification.emit(syncMessage);
    });
  }

  private exportExcelProductNotificationEvents(): void {
    this._hubConnection.on("ExportExcelProductLogAction", (data: SyncProductsNotificationSignalR) => {
      let syncMessage = new SyncProductsNotificationSignalR(data);
      this.exportExcelProductNotification.emit(syncMessage);
    });
  }

  private syncSaleChannelPricesNotificationEvents(): void {
    this._hubConnection.on("SyncSaleChannelPricesLogAction", (data: SyncProductsNotificationSignalR) => {
      let syncMessage = new SyncProductsNotificationSignalR(data);
      this.syncSaleChannelPricesNotification.emit(syncMessage);
    });
  }

  private logNotificationEvents(): void {
    this._hubConnection.on('LogNotificationAction', (data: Log) => {
      let log = new Log(data);
      this.logNotification.emit(log);
    });
  }

  public startSignalRService(token) {
    this.createConnection(token);
    this.orderNotificationEvents();
    this.bulkOrderNotificationEvents();
    this.wmsOrderAssignedNotificationEvents();
    this.wmsOrderTakenNotificationEvents()
    this.importExcelProductNotificationEvents();
    this.exportExcelProductNotificationEvents();
    this.syncSaleChannelPricesNotificationEvents();
    this.logNotificationEvents();
    this.startConnection();
    this.onConnectionClose();
  }

  private resetSignalRConnection() {
    this.commonService.startOrStopSignalRConnectionEventEmitter.subscribe((res: boolean) => {
      if (res == true) {
        this.startConnection();
      } else {
        this.stopConnection();
      }
    });
  }

  private prepareLogsUrl(logs: Array<Log>): Array<Log> {
    logs.forEach(log => {
      if (log.logApplication != null && log.identifier != null) {

        switch (log.logApplication) {
          case LogApplication.ProductPrices: {
            log.url = `${environment.clientUrl}/products-prices?search=${log.identifier}`
            break;
          }
        } 
      }
    });
    return logs;
  }

  // https://stackoverflow.com/questions/54009048/error-server-returned-an-error-on-close-no-app-server-is-currently-connected-t

  //  The SignalR.HubConnection has the properties serverTimeoutInMilliseconds and keepAliveIntervalInMilliseconds.

  //  serverTimeoutInMilliseconds

  //  The server timeout in milliseconds.

  //  If this timeout elapses without receiving any messages from the server, the connection will be terminated with an error.The default timeout value is 30, 000 milliseconds(30 seconds).

  //  keepAliveIntervalInMilliseconds

  //  Default interval at which to ping the server.

  //  The default value is 15, 000 milliseconds(15 seconds).Allows the server to detect hard disconnects(like when a client unplugs their computer).

  //  I just set these values to greater numbers.
  //  this.hubConnection.serverTimeoutInMilliseconds = 300000;
  //  this.hubConnection.keepAliveIntervalInMilliseconds = 300000;
}
