import { Injectable, Inject } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { tap, catchError, switchMap } from "rxjs/operators";
import { CustomerDetailService } from "../services/customer-detail.service";
import { AuthService } from "../services/auth.service";
import { SnackBarNotificationService, SnackBarNotificationType } from "../services/snackBar-notification.service";
import { Observable, throwError } from "rxjs";
import { ApiError } from "../company/models/ApiError";
import { isString } from "lodash";

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  redirectUriOlx: string;

  constructor(
    private customerDetailService: CustomerDetailService,
    private authService: AuthService,
    private snackBarNotificationService: SnackBarNotificationService,
    @Inject("OLX_REDIRECT_URI") olxRedirectUri: string
  ) {
    this.redirectUriOlx = olxRedirectUri;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(
      switchMap((accessToken: any) => {
        if (
          accessToken &&
          accessToken.length > 0 &&
          !request.url.includes("https://translation.googleapis.com/language/translate")
        ) {
          let authRequest = request;

          let newHeader = request.headers.set("Authorization", "Bearer " + accessToken);

          const customerDetails = this.customerDetailService.customerDetailValue;
          if (customerDetails && customerDetails !== null) {
            newHeader = newHeader.set("c_uid", `${customerDetails.currentCompany.id}`);
          }

          authRequest = request.clone({
            headers: newHeader,
          });

          return next.handle(authRequest).pipe(
            tap((evt) => {
              if (evt instanceof HttpResponse) {
                if (evt.ok == true) {
                  // do something
                }
              }
            }),
            catchError((err: HttpErrorResponse, caught) => {
              if (
                err instanceof HttpErrorResponse &&
                err.error instanceof Blob &&
                err.error.type === "application/json"
              ) {
                this.handleBlobErrorObservable(err);
              } else {
                this.handleErrorObservable(err);
              }

              return throwError(err);
            })
          );
        } else {
          return next.handle(request);
        }
      })
    );
  }

  private handleErrorObservable(error: HttpErrorResponse): void {
    let apiError: ApiError = error.error;

    let errMsg: string = "";
    if (apiError != null) {
      if(isString(apiError)){
        apiError = JSON.parse(apiError)
      }
      
      errMsg = apiError.message != null ? apiError.message : "";
      
      if (error.status == 0) {
        errMsg += "Unable to connect to GlocalSoft Api.";
      }

      if (apiError.moduleName != null && apiError.moduleName.length > 0) {
        errMsg += `: ${apiError.moduleName};`;
      }

      for (let key in apiError.errors) {
        errMsg += ` '${key}': ${apiError.errors[key].join(",")}`;
      }
    }

    if (errMsg.length == 0) {
      errMsg += error.message;
    }

    //if (error.status === 401 || error.status === 403) {
    //  this.logout();
    //}

    this.snackBarNotificationService.showMessage(
      SnackBarNotificationType.Error,
      errMsg,
      10000,
      error.status.toString()
    );
  }

  private handleBlobErrorObservable(error: HttpErrorResponse): void {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      const apiError: ApiError = JSON.parse((<any>e.srcElement).result);

      let errMsg: string = apiError.message != null ? apiError.message : "";
      if (error.status == 0) {
        errMsg += "Unable to connect to GlocalSoft Api.";
      }

      if (apiError.moduleName != null && apiError.moduleName.length > 0) {
        errMsg += `: ${apiError.moduleName};`;
      }

      for (let key in apiError.errors) {
        errMsg += ` '${key}': ${apiError.errors[key].join(",")}`;
      }

      if (errMsg.length == 0) {
        errMsg = (<any>e.srcElement).result;
      }

      this.snackBarNotificationService.showMessage(
        SnackBarNotificationType.Error,
        errMsg,
        10000,
        error.status.toString()
      );
    });
    reader.readAsText(error.error);
  }
}
