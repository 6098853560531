import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { CustomerDetailService } from "../../services/customer-detail.service";
import { UserDetail } from "../../company/models/UserDetail";
import { filter, map, switchMap } from "rxjs/operators";

@Injectable()
export class CompanyEditGuard implements CanActivate {
  constructor(private customerDetailService: CustomerDetailService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    let companyId: string = next.params.id;

    return this.customerDetailService.customerDetailObservable.pipe(
      filter(data => data !== null),
      switchMap(customerDetail => {
        if (customerDetail != null) {
          return of(this.validateModule(customerDetail, companyId));
        } else {
          return this.customerDetailService.getUserDetails().pipe(
            switchMap((res: UserDetail) => {
              return of(this.validateModule(res, companyId));
            })
          );
        }
      })
    );
    
  }

  validateModule(customerDetail: UserDetail, companyId: string): boolean {
    if (customerDetail.currentCompany.id == +companyId) {
      
      return true;
    } else {
      this.router.navigate(["/home"]);
      return false;
    }
  }
}
