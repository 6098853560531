import { Inject, Injectable, OnDestroy } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, Subject, of } from "rxjs";
import { tap, map, catchError, takeUntil, switchMap } from "rxjs/operators";
import { CustomerDetailService } from "../customer-detail.service";
import { UserDetail } from "../../company/models/UserDetail";
import { TranslateModulesEnum } from "../../company/models/Enums";

@Injectable()
export class GoogleTranslateService implements OnDestroy {
  private _unsubscribeAll: Subject<any>;
  customerDetail: UserDetail;

  public API_KEY: string = "  ";
  private translateUrl = "https://translation.googleapis.com/language/translate/v2?key=";

  apiUrl: string;

  constructor(
    private http: HttpClient,
    private customerDetailService: CustomerDetailService,
    @Inject("API_URL") apiUrl: string
  ) {
    this.apiUrl = apiUrl;
    this._unsubscribeAll = new Subject();
    this.customerDetail = this.customerDetailService.customerDetailValue;

    let moduleSettings = this.customerDetail.currentCompany.translateModules.find(
      (x) => x.type == TranslateModulesEnum.GoogleTranslate
    );

    if (moduleSettings != null) {
      this.API_KEY = moduleSettings.settingValues.find((x) => x.name == "api.token").value;
    } else {
      this.API_KEY = "";
    }

    // this.customerDetailService.customerDetailObservable.pipe(takeUntil(this._unsubscribeAll)).subscribe((res: CustomerDetail) => {
    //   if (res != null && res.currentCompany.settings.translateApiKey != null) {
    //     this.API_KEY = res.currentCompany.settings.translateApiKey;
    //   }
    //   else {
    //     this.API_KEY = '';
    //   }
    // });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  translate(
    propertyName: string,
    textToTranslate: string,
    source: string,
    target: string,
    withDiacritics: boolean = true
  ): Observable<any> {
    const body = {
      q: textToTranslate,
      source: source,
      target: target,
    };

    return this.http.post<any>(this.translateUrl + this.API_KEY, body).pipe(
      map((result) => ({
        name: propertyName,
        value: this.convertHTML(result.data.translations[0].translatedText, withDiacritics),
      })),
      catchError(this.handleError)
    );
  }

  translateToRo(textToTranslate: any, source: string): Observable<any> {
    const body = {
      q: textToTranslate,
      source: source,
      target: "ro",
    };

    return this.http.post<any>(this.translateUrl + this.API_KEY, body).pipe(
      map((result) => ({ value: result.data.translations[0].translatedText, failed: false })),
      catchError((result) => of({ failed: true }))
    );
  }

  private handleError(err: HttpErrorResponse) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  private convertHTML(text: string, withDiacritics: boolean) {
    var convertedText = text
      .split("&amp;")
      .join(" &")
      .split("&lt;")
      .join(" <")
      .split("&gt;")
      .join(" >")
      .split("&quot;")
      .join('"')
      .split("&apos;")
      .join("'");

    return withDiacritics ? convertedText : this.replaceDiacritics(convertedText);
  }

  getTranslation(source: string, target: string, text: string): Observable<any> {
    return this.http.get(this.apiUrl + "/api/Translation/GetTranslation", {
      params: {
        source: source,
        target: target,
        text: text,
        translationModule: TranslateModulesEnum.GoogleTranslate,
      },
    });
  }

  createTranslation(source: string, target: string, text: string, value: string): void {
    this.http
      .post(this.apiUrl + "/api/Translation/CreateTranslation", {
        source: source,
        target: target,
        text: text,
        value: value,
        translationModule: TranslateModulesEnum.GoogleTranslate,
      })
      .subscribe();
  }

  translateWithCache(propertyName: string, text: string, source: string, target: string): Observable<any> {
    return this.getTranslation(source, target, text).pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((res) => {
        if (res) {
          return of(res.value);
        }
        return this.translate(propertyName, text, source, target);
      }),
      switchMap((res) => {
        if (res.value) {
          // daca vine cu proprietatea value inseamna ca s-a tradus, daca nu inseamna ca s-a luat din baza de date
          this.createTranslation(source, target, text, res.value);
          return of({ name: propertyName, value: res.value });
        }
        return of({ name: propertyName, value: res });
      })
    );
  }

  private replaceDiacritics(text: string) {
    return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
}
