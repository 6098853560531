import { Injectable, EventEmitter } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ParentProductDetail } from "../company/models/ParentProductDetail";

@Injectable()
export class CommonService {

  constructor() {

  }

  startOrStopSignalRConnectionEventEmitter = new EventEmitter<boolean>();

  resetDataFromCacheEventEmitter = new EventEmitter<boolean>();

  resetDataFromEmagAwbServiceEventEmitter = new EventEmitter<boolean>();

  resetDataFromVivreAwbServiceEventEmitter = new EventEmitter<boolean>();

  resetDataFromCelAwbServiceEventEmitter = new EventEmitter<boolean>();

  modalGalleryActiveEventEmitter = new EventEmitter<boolean>();

  private checkProductStockSubject = new BehaviorSubject<Array<string>>([]);
  checkProductStockObservable = this.checkProductStockSubject.asObservable();

  private startSignalRConnectionSubject = new BehaviorSubject<boolean>(false);
  startSignalRConnectionObservable = this.startSignalRConnectionSubject.asObservable();

  private stopSignalRConnectionSubject = new BehaviorSubject<boolean>(false);
  stopSignalRConnectionObservable = this.stopSignalRConnectionSubject.asObservable();

  checkQuantityStockOfProductsCodes(productsCodes: Array<string>) {
    this.checkProductStockSubject.next(productsCodes);
  }

  startSignalRConnection(value: boolean) {
    this.startSignalRConnectionSubject.next(value);
  }

  stopSignalRConnection(value: boolean) {
    this.startSignalRConnectionSubject.next(value);
  }

  private parentProducLiveEditSubject = new BehaviorSubject<ParentProductDetail>(null);
  parentProductLiveEditObservable = this.parentProducLiveEditSubject.asObservable();

  updateParentProductLiveEdit(value: ParentProductDetail) {
    this.parentProducLiveEditSubject.next(value);
  }

  private checkCrmClientSubject = new BehaviorSubject<Array<string>>([]);
  checkCrmClientObservable = this.checkCrmClientSubject.asObservable();

  updateCrmClients(value: Array<string>) {
    this.checkCrmClientSubject.next(value);
  }
}
