
export enum LogApplication {
  StockSync = 1,
  BuyButton,
  ProductPrices,
  Order,
  OrderCron,
  ImportExcelProduct,
  ExportExcelProduct,
  SyncSaleChannelPrices,
  SyncErpSaleChannelPrices,
  FeedIn,
  FeedOut,
  MapProducts
}
