import { Company } from "./Company";
import { CompanyRole } from "./CompanyRole";
import { User } from "./User";

export class UserDetail {
  user: User;
  currentCompany: Company;
  companyRoles: Array<CompanyRole>;
  approvedCustomerCompanyRole: boolean;
  allCompanies: Array<Company>;
  companyModules: Array<string>;

  constructor(data?) {
    data = data || {};

    this.allCompanies = new Array<Company>();
    if (data.allCompanies != null) {
      data.allCompanies.forEach((item) => {
        this.allCompanies.push(new Company(item));
      });
    }
    this.allCompanies = data.allCompanies;
    this.user = new User(data.user) || new User();
    this.currentCompany = new Company(data.currentCompany) || new Company();
    this.companyRoles = data.companyRoles !== null ? data.companyRoles :  new Array<CompanyRole>();
    this.approvedCustomerCompanyRole = data.approvedCustomerCompanyRole || false;
    this.companyModules = data.companyModules || new Array<string>();
  }
}
